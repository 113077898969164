import React, { Component } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import InsideBanner from '../components/insidebanner'
import { Container, Header, Accordion, Icon } from 'semantic-ui-react'

class QuestionsAboutTourettes extends Component {
  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
      <Layout location="questions">
        <SEO title="Questions About Tourettes" />
        <InsideBanner headline="Questions About Tourette Syndrome" src="/img/banners/Eisenreich.Phillies-e1523836613569.jpg" />
        <Container style={{ marginBottom: '35px' }} vertical>
          <Header as='h2' content="General Questions" />
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
              <Icon name='dropdown' />
              What are tics?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>There are two main categories of tics: Motor tics and Vocal tics.</p> 
              <p>Motor tics are sudden repetitive movements of the muscles of the body that occur repeatedly.</p>
              <p>Vocal tics are in the muscles that control speech and cause involuntary sounds.</p>
              <p>Most children develop an eye tic first. Other tics develop shortly afterwards with the intensity increasing during early adolescence. Tics can be embarrassing and even painful. Tics tend to take place less during sleep and more in times when the child is under stress. They can also increase when a child is sick or suffering from allergy symptoms.</p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Why can't tics be controlled?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>The symptoms of TS are involuntary, although some people can sometimes suppress, camouflage, or otherwise manage their tics in an effort to minimize their impact on functioning. However, people with TS often report a substantial buildup in tension when suppressing their tics to the point where they feel that the tic must be expressed. Tics in response to an environmental trigger can appear to be voluntary or purposeful but are not.</p>
              <p>Tics are often worse with excitement or anxiety and better during calm, focused activities. Certain physical experiences can trigger or worsen tics, for example tight collars may trigger neck tics, or hearing another person sniff or throat-clear may trigger similar sounds. Tics do not go away during sleep but are often significantly diminished.</p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Is Tourette Syndrome inherited?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <p>Studies have shown there is a link to TS being genetic. However, it's also noted that it doesn't manifest itself in every family member. In fact, some families have multiple siblings, as well as adults and several generations with TS, while others may only have one sibling with TS and even skip a generation to find another.</p>
              <p>Although there may be a few genes with substantial effects, it is also possible that many genes with smaller effects and environmental factors may play a role in the development of TS. Genetic studies also suggest that some forms of ADHD and OCD are genetically related to TS, but there is less evidence for a genetic relationship between TS and other neurobehavioral problems that commonly co-occur with TS. It is important for families to understand that genetic predisposition may not necessarily result in full-blown TS; instead, it may express itself as a milder tic disorder or as obsessive-compulsive behaviors. It is also possible that the gene-carrying offspring will not develop any TS symptoms. </p>
              <p>The sex of the person also plays an important role in TS gene expression. At-risk males are more likely to have tics and at-risk females are more likely to have obsessive-compulsive symptoms. </p>
              <p>People with TS may have genetic risks for other neurobehavioral disorders such as depression or substance abuse. Genetic counseling of individuals with TS should include a full review of all potentially hereditary conditions in the family.</p>
            </Accordion.Content>
          </Accordion>
          <Header as='h2' content="Questions Jim Is Asked" />
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
              <Icon name='dropdown' />
              What tics do you have?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <p>Some of my tics were eye blinking, facial grimaces, jerking head to one side or the other, sniffling, coughing, clearing throat, shoulder shruggs, and rolling my stomach.</p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Did you get bullied or teased as a child?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              <p>I was teased as a kid. They called me names like 'snorky' and 'spaz'. Other kids would also mimmic my tics.</p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 5} index={5} onClick={this.handleClick}>
              <Icon name='dropdown' />
              How did you handle teasing as a child?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
            <p>I didn't react very much because I didn't know why I did these things. I would usually just walk away or ignore them, and try to avoid those situations the best I could.</p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 6} index={6} onClick={this.handleClick}>
              <Icon name='dropdown' />
              My child is getting bullied. How can I help?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
              <p>Bullying is a serious problem that we hear about all too frequently from parents and children. We believe that bullying has long-term and lasting effects on everyone involved, including parents. Nothing is more painful than to know that your child is being bullied and to feel helpless to know how best to respond.</p>
              <p>As a young boy, Jim was bullied. I think he may have felt much the same as your child - hoping that it would get better and not wanting to draw any more attention. Unfortunately, the messages Jim and all of the other children who are bullied often say to themselves is negative. "I must not be ok. Other kids don't like me. I must be bad."</p>
              <p>Please know that bullying is not ok. It is not acceptable behavior at any stage of a child's development. While it is important that schools get involved to support children that are being bullied, it is often depended on the parents to provide their child with the specific tools necessary to combat bullying.</p>
              <p>We have found several resources that are effective, authentic and helpful. SuEllen Fried of Prairie Village, Kansas has studied the topic of bullying for many years. We find her advice very sound. She has co-author a book on bullying with Blanche Sosland "Banishing Bullying Behavior" (Rowman &amp; Littlefield Education), and she gives anti-bullying seminars in schools around the country. <a href="http://www.bullysafeusa.com" target="_blank">www.bullysafeusa.com</a></p>
              <p>You can also visit <a href="http://www.stopbullying.gov" target="_blank">www.stopbullying.gov</a> and <a href="/resources">download our resource guides for educators and administrators</a> to learn about tools for coping with Tourette Syndrome.</p>
              <p>I hope you find these resources helpful.</p>
              <p>Dana McMillan<br/>Board Member, Jim Eisenriech Foundation for Children with Tourette Syndrome</p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 7} index={7} onClick={this.handleClick}>
              <Icon name='dropdown' />
              How could you hit a baseball if you were having a tic?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 7}>
              <p>I never had a problem with hitting because I could hold the tics in for the few seconds it takes a pitcher to throw a pitch.</p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 8} index={8} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Are there times when your tics are worse?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 8}>
              <p>My tics got worse when I was in a setting (like school or a movie or church) that was more quiet or controlled. I didn't want to be a distraction in these times so I tried to hold my tics in, but the more I tried, the worse they got.</p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 9} index={9} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Do any of your kids have Tourette?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 9}>
              <p>None of Jim's kids have Tourette syndrome.</p>
              <p>The early symptoms of TS are almost always noticed first in childhood, with the average onset between the ages of 7 and 10 years. TS occurs in people from all ethnic groups; males are affected about three to four times more often than females. It is estimated that 200,000 Americans have the most severe form of TS, and as many as one in 100 exhibit milder and less complex symptoms such as chronic motor or vocal tics or transient tics of childhood. Although TS can be a chronic condition with symptoms lasting a lifetime, most people with the condition experience their worst symptoms in their early teens, with improvement occurring in the late teens and continuing into adulthood. </p>
            </Accordion.Content>
          </Accordion>
        </Container>
      </Layout>
    );
  }
}

export default QuestionsAboutTourettes
